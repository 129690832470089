.box-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap; /* Allow boxes to wrap onto the next line */
}

.box {
    width: 30%;
    height: 20%;
    padding: 1vh;
    text-align: center;
    line-height: 0.3;
}

/* Apply margin-right to every third box */
.box:nth-child(3n) {
    margin-right: 0;
}

/* Clear margin for the first box in each row */
.box:nth-child(3n+1) {
    margin-right: 1vh;
}

.box h3 {
    margin-top: 1vh;
    color: #333;
}

.box p {
    margin-bottom: 0;
    color: #666;
}

.box img {
    margin-bottom: 2vh;
    max-width: 95%;
}

@media screen and (max-width: 768px) {
    .box h3 {
        font-size: 0.8em; /* Adjust the font size for smaller screens */
    }
    .box p {
      font-size: 0.5em; /* Adjust the font size for smaller screens */
    }
}