footer {
  display: flex;
  justify-content: space-between;
  background-color: #393338;
  line-height: 0.3;
  color: #F3EFE2;
}

footer>div {
  flex: 1;
  margin: 3vh;
  padding: 10px; /* Add padding of 10 pixels */
  white-space: nowrap; /* Prevent text wrapping */
}

.social-media {
  width: 3vh;
  margin: 1vh;
}

@media screen and (max-width: 768px) {
  footer {
    font-size: 0.4em; /* Adjust the font size for smaller screens */
  }
  .social-media {
    width: 1.5vh;
    margin: 0.5vh;
  }
}
