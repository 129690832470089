.banner {
    width: 100%;
    height: 40vh; /* 5% of the viewport height */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.button-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: antiquewhite;
    color: black;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button-link:hover {
    background-color: maroon;
  }
  
  @keyframes gunshot {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(-200px);
    }
    100% {
      opacity: 0;
      transform: translateY(-400px);
    }
  }
  
  .gunshot {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    animation: gunshot 1.5s linear; /* Apply animation */
  }
  