@import url(./Font.css);

body {
  background-color: #F3EFE2;
}

p {
  font-family: 'paragraph-font';
}

h1 {
  text-align: center;
  font-family: 'title-fonts';
}

h2 {
  text-align: center;
  font-family: 'title-fonts';
}

h3 {
  text-align: center;
  font-family: 'title-fonts';
}

h4 {
  text-align: center;
  font-family: 'title-fonts';
}

h5 {
  font-family: 'title-fonts';
}

.page-heading {
  font-size:larger;
  text-align: center;
  padding: 2vh;
  font-family: 'title-fonts';
}

.nav-heading {
  text-align: center;
  font-family: 'title-fonts';
}

.page-subheading {
  font-size:larger;
  padding-top: 1vh;
  font-family: 'title-fonts';
}

.just-heading {
  padding-top: 1vh;
  font-family: 'title-fonts';
}

.custom-modal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.background-div {
  width: 100%;
  height: 100%;
  /* Set the height as needed */
  /* background-image: url('../images/Web_design_TBS.jpg'); */
  /* Specify the path to your image */
  background-size: cover;
  /* Cover the entire background */
  background-position: center;
  /* Center the background image */
  /* Additional background properties can be added here */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: antiquewhite;
}

.close-button::before,
.close-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #000;
}

.close-button::before {
  transform: rotate(45deg);
}

.close-button::after {
  transform: rotate(-45deg);
}

.close-button:hover::before,
.close-button:hover::after {
  border-color: #666;
}

@font-face {
  font-family: 'Regular';
  src: url('../fonts/Poppins/Poppins/Poppins-Medium.ttf') format('ttf');
  /* Add additional font formats here if needed */
}

