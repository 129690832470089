.whatsapp-button {
    position: fixed;
    bottom: 80px;
    right: 20px;
    background-color: transparent;
    color: white;
    border: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add a shadow */
    background-image: url('../images/WhatsApp.png'); /* Replace 'path/to/your/image.png' with the path to your image */
    background-size: cover;
    background-position: center;
  }
  
  /* Adjustments for hover effect */
  .whatsapp-button:hover {
    background-color: rgba(0, 0, 0, 0.3); /* Add a transparent overlay on hover */
  }
  