.header {
    width: 100%;
    height: 13vh; /* 5% of the viewport height */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Required for absolute positioning */
}

.logo {
    max-height: 80%; /* Ensure the logo fits within the header height */
    max-width: 100%; /* Ensure the logo fits within the header width */
    z-index: 1;
}

.sherif {
    position: absolute;
    top: 0;
    right: 0;
    height: 120%; /* Adjust the height of the image to match the header */
    width: auto; /* Ensure the width adjusts automatically */
    z-index: 1; /* Ensure the image appears above the navigation bar */
}

@media screen and (max-width: 768px) {
    .sherif {
        position: absolute;
        top: 0;
        right: 0;
        height: 90%; /* Reduce the height to 100% */
        width: auto; /* Ensure the width adjusts automatically */
        z-index: 1; /* Ensure the image appears above the navigation bar */
        transform: translateY(30%); /* Move the image down by 20% of its height */
    }
    
    
}