.container {
    display: flex;
    flex-direction: column;
  }
  
  .row {
    display: flex;
  }
  
  .gallery-box {
    width: 20vh; /* Adjust the width as needed */
    height: 20vh; /* Adjust the height as needed */
    text-align: center;
    line-height: 0.3;
    flex: 1 1 25%;
  }

  .gallery-box img {
    width: 18vh;
  }
  
  .single-box {
    width: 40vh; /* Adjust the width as needed */
    height: 40vh; /* Adjust the height as needed */
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .single-box img {
    width: 40vh;
    height: 38vh; /* Adjust the height as needed */
  }
.gallery-row {
    display: flex;
    /* Adjust alignment as needed */
    align-items: flex-start;
    /* Adjust alignment as needed */
    justify-content: center;
    /* Add margin between rows */
    flex-wrap: wrap;
}