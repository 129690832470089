.nav-bar {
  text-align: center;
  justify-content: center;
}

.nav-item {
  margin-right: 2vh;
  flex-grow: 1; /* Make each item take up equal space */
  text-align: center; /* Center the text within each item */
}

.nav-item:last-child {
  margin-right: 0; /* Remove margin from the last item */
}

.nav-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.nav-item :hover {
  color: rgb(55, 7, 7);
}

@media screen and (max-width: 768px) {
  h3 {
      font-size: 0.8em; /* Adjust the font size for smaller screens */
  }
  p {
    font-size: 0.8em; /* Adjust the font size for smaller screens */
  }
}