.contact-container{
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 2vh;
}
.contact-details-container{
    flex: 1;
    width: 50%;
    text-align: center;
}
.contact-details {
    margin-left: 20%;
    text-align: left;
}

.contact-details h3{
    text-align: left;
}

.contact-location-container {
    flex: 1;
    width: 50%;
    display: flex;
    justify-content: flex-end; /* Aligns the image to the right */
    align-items: center; /* Centers items vertically */
}

.contact-location img {
    width: 60%;
    display: block; /* Ensures margin: auto works */
    margin: auto; /* Centers the image horizontally */
}