@font-face {
    font-family: 'paragraph-font';
    src: url('../fonts/Poppins/Poppins/Poppins-Medium.ttf') format('truetype');
    /* Add additional font formats here if needed */
  }

  @font-face {
    font-family: 'title-fonts';
    src: url('../fonts/Chevi/Chervels.ttf') format('truetype');
  }
  