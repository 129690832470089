/* ImgCaption.css */

.img_caption_container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content vertically */
    margin-bottom: 20px; /* Add margin for spacing */
    max-width: 30%;
  }
  
  .img_caption {
    display: flex; /* Use flexbox for the image and caption */
    align-items: center; /* Center the items vertically */
    max-width: 100%; /* Ensure the container doesn't exceed the width of its parent */
  }
  
  .img_caption img {
    max-width: 100px; /* Adjust the maximum width of the image */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Add spacing between the image and caption */
  }
  
  .captionWrapper {
    display: flex;
    flex-direction: column; /* Arrange caption header and caption vertically */
  }
  
  .captionWrapper h2 {
    margin: 0; /* Remove margin for the caption header */
  }
  
  .caption {
    margin: 0; /* Remove margin for the caption */
  }
  
  @media screen and (max-width: 768px) {
    .img_caption {
      flex-direction: column; /* Stack image and caption vertically on smaller screens */
      text-align: center; /* Center the content horizontally */
    }
  
    .img_caption img {
      margin-right: 0; /* Remove margin for the image */
      margin-bottom: 10px; /* Add spacing between the image and caption */
    }
  }
  