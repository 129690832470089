.img-caption-wrapper {
    display: flex;
    align-items: center;
    /* Align items vertically */
    width: 100%;
    /* Ensure the container takes up the full width */
    margin-bottom: 1vh;
}

.img-caption-wrapper img {
    width: 40%;
    /* Adjust the width of the image */
    height: auto;
    /* Maintain aspect ratio */
}

.img-caption-wrapper .caption {
    flex-grow: 1;
    /* Allow the caption to take up remaining space */
    padding: 0 20px;
    /* Adjust spacing between image and caption */
}

@media screen and (max-width: 768px) {
    .img-caption-wrapper {
        display: flex;
        flex-direction: column; /* Stack image and caption vertically on smaller screens */
        align-items: center; /* Center the content horizontally */
        width: 100%;
    }

    .img-caption-wrapper img {
        width: 90%; /* Adjust the width of the image to 90% of the screen */
        height: auto; /* Maintain aspect ratio */
    }
}

