/* modal.css */

.modal-content {
  background-image: url('../images/modal-bg.png');
  background-size: cover;
  padding: 20px;
  border: none;
  border-radius: 10px;
  height: 35%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: antiquewhite;
}

.form-input-container {
  display: flex;
  margin: 2vh;
  text-align: center;
  justify-content: center;
}

input {
  margin: 2vh;
}

@media screen and (max-width: 768px) {
  .modal-content {
    height: 40%;
  }
  .form-input-container{
    font-size: 0.6em; /* Adjust the font size for smaller screens */
  }
}