.image-gallery {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: space-between;
  margin-right: 2.5vh;
}

.image-container {
  flex: 0 0 calc(25% - 20px); /* Adjust for margins */
  margin-bottom: 20vh;
  text-align: center;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.image-container img {
  max-width: 70%;
  max-height: 70%;
  object-fit: cover;
}

.image-container p {
  margin: 2vh;
}
