.banner {
  width: 100%;
  height: 100%; /* Adjust height as needed */
  overflow: hidden;
  position: relative;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio */
  transition: transform 0.5s ease-in-out; /* Smooth transition */
}
